/************************************************************************************
HEADER
*************************************************************************************/

.header {
  //position: absolute;
  position: relative;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  z-index: 130;
  @include rem(margin-top, 32px);
  @include rem(margin-bottom, 64px);

  .inner {
    @include rem(height, 80px);
    background: $black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    @include rem(padding, 0 30px);
    @include transition(all, .3);
  }

  .header-r {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.main-nav {
  vertical-align: top;
  text-align: left;
  height: 100%;
  display: flex;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
    height: 100%;
    @include rem(margin, 0 -12px);

    li {
      @include rem(padding, 0 12px);
      margin: 0;
      display: flex;
      align-items: center;
      height: 100%;

      a {
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        @include transition(all, .2);

        &:after {
          content: '';
          width: 0;
          height: 8px;
          background: $red;
          position: absolute;
          left: 0;
          bottom: 0;
          //display: none;
        }

        &:hover:after,
        &.active:after {
          width: 100%;
          @include transition(all, .2);
        }
      }
    }
  }
}

.dropdown {
  position: absolute;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  @include rem(padding-top, 144px);
  @include rem(padding-bottom, 40px);
  //display: none;
  @include translate(0,-100%);
  @include transition(all, .3);
  margin-top: -10px;

  .head {
    @include rem(font-size, 24px);
    line-height: 1.3em;
    color: $black;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .nav-item {
    border-top: 4px solid #DEE0E2;
    padding-top: 20px;
    padding-left: 24px;
    padding-right: 24px;

    ul {
      li {
        font-weight: 700;
        padding-left: 20px;
        position: relative;
        margin-bottom: 10px;

        &:before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-left: 1.5px solid $red;
          border-bottom: 1.5px solid $red;
          @include rotate(-135deg);
          position: absolute;
          left: 0;
          top: 12px;
        }

        a {
          color: $red;
        }
      }
    }
  }

  &.active {
    //display: block;
    animation: showDropdown .5s;
    @include translate(0,0);
    margin-top: 0;
  }
}

.cat-box {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 24px;

  .item-inner {
    display: flex;
    width: 100%;
    text-decoration: none;

    &:hover {
      .head {
        text-decoration: underline;
      }

      .more-link.alt {
        &:before {
          border: 2px solid $red;
          background: $red url('../img/arr-r.svg') center center no-repeat;
        }
      }
    }
  }

  .img {
    flex: 0 0 50%;
    max-width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text {
    flex: 0 0 50%;
    max-width: 50%;
    background: #DEE0E2;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .more-link.alt {
    color: $red;

    &:before {
      border: 2px solid $red;
    }
  }
}

.search-btn {
  margin-left: 30px;
  color: $red;
  @include transition(all, .3);

  &:hover {
    color: #fff;
  }

  .icon {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
  }
}

.teaser-hp {
  display: flex;
  max-height: 100vh;
  min-height: 700px;
  @include rem(margin-top, -176px);
  overflow: hidden;

  .teaser-img {
    flex: 0 0 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .teaser-content {
    flex: 0 0 100%;
    margin-left: -100%;
    display: flex;
    justify-content: flex-end;
    max-height: 100vh;
    min-height: 700px;

    .container, .row, .col-12 {
      height: 100%;
    }

    .col-12 {
      display: flex;
      align-items: flex-end;
    }

    .inner {
      background: #c7c6c6;
      max-width: 550px;
      @include rem(padding, 50px);
      border-radius: 10px 10px 0 0;
    }

    h2, .h2 {
      color: $black;

      a {
        color: $black;
        text-decoration: none;

        &:hover {
          color: $red;
        }
      }
    }

    .more {
      text-decoration: none;
      font-weight: 600;
      color: $red;
    }
  }
}

.teaser-alt {
  display: flex;
  @include rem(margin-top, -176px);
  overflow: hidden;
  //max-height: 336px;
  position: relative;
  color: #fff;

  .teaser-img {
    flex: 0 0 100%;
    width: 100%;
    position: relative;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    min-height: 336px;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .teaser-content {
    flex: 0 0 100%;
    width: 100%;
    //margin-left: -100%;
    display: flex;
    align-items: center;
    @include rem(padding, 140px 0);
    position: relative;
    z-index: 20;

    &.omega {
      padding-bottom: 0;
    }
  }

  .inner {
    @include rem(padding-top, 15px);
  }

  h1 {
    color: #fff;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.teaser-products {
  display: flex;
  @include rem(margin-top, -176px);
  overflow: hidden;
  //max-height: 336px;
  position: relative;
  color: #fff;

  .teaser-img {
    flex: 0 0 100%;
    width: 100%;
    position: relative;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    min-height: 336px;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .teaser-content {
    flex: 0 0 100%;
    width: 100%;
    //margin-left: -100%;
    display: flex;
    align-items: center;
    @include rem(padding, 140px 0 50px 0);
    position: relative;
    z-index: 20;

    &.omega {
      padding-bottom: 0;
    }
  }

  .inner {
    @include rem(padding-top, 15px);
  }

  h1 {
    color: #fff;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.nav-btn {
  display: none;
  vertical-align: top;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  z-index: 2001;
  cursor: pointer;
  align-items: center;
  margin-left: 15px;

  .lines {
    width: 30px;
    height: 4px;
    flex: 0 0 30px;
    display: block;
    position: relative;
    position: absolute;
    top: 50%;
    margin-top: -2px;
  }

  .line-1,
  .line-2,
  .line-3 {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: 0.1s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 0.28571rem center;
  }

  .line-1 {
    top: 0;
    transition: opacity 0.1s 0.1s ease;
  }

  .line-2 {
    top: 10px;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  .line-3 {
    top: -10px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  &.active {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    &:hover {
      .line-1,
      .line-2,
      .line-3 {
          background: #000;
      }
    }
  }
}

.mobile-nav {
  background: $black;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 90;
  overflow-y: auto;
  max-height: 100%;
  margin-top: -10px;
  @include rem(padding, 130px 0 30px 0);
  @include transition(all, .3);
  @include translate(0,-100%);

  &.active {
    @include translate(0,0);
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      color: #fff;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-bottom: 1px solid rgba(255,255,255,0.1);

      &.active {
        a {
          color: #ffa70e;
        }
      }

      &:last-child {
        border: none;
      }

      a {
        font-size: 15px;
        font-weight: 400;
        display: block;
        width: 100%;
        padding: 14px 0;
        color: #fff;
        border: none;
        position: relative;
        text-decoration: none;
      }
    }
  }
}

.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 80;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  display: none;
}

.mobile-nav-wrap {
  display: none;
}

body.mobile-nav-opened {
  .header {
    .inner {
      padding: 0;
    }
  }

  .mobile-nav {    
    @include translate(0,0);
    margin-top: 0;
  }

  .nav-btn {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    &:hover {
      .line-1,
      .line-2,
      .line-3 {
        background: #fff;
      }
    }
  }

  .mobile-nav-overlay {
    display: block;
    animation: showOverlay .4s;
  }
}

html.i-amphtml-fie>body.mobile-nav-opened,
html.i-amphtml-singledoc>body.mobile-nav-opened {
  overflow: hidden !important;
}

@keyframes showOverlay {
  0% {
    display: none;
    background: rgba(0,0,0,0);
  }
  5% {
    display: block;
    background: rgba(0,0,0,0);
  }
  100% {
    display: block;
    background: rgba(0,0,0,0.9);
  }
}

@keyframes showDropdown {
  0% {
    //display: none;
    @include translate(0,-100%);
  }
  5% {
    //display: block;
    @include translate(0,-100%);
  }
  100% {
    //display: block;
    @include translate(0,0);
  }
}