/************************************************************************************
RESET
*************************************************************************************/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section, menu,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }

:focus { outline: 0; }

table { border-collapse: collapse; border-spacing: 0; }

article, aside, figure, footer, header, hgroup, nav, section, img { display: block; }

input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

label, input[type=button], input[type=submit], button { cursor: pointer; overflow: visible; }

em, i { font-weight: inherit; }

/************************************************************************************
GENERAL
*************************************************************************************/

body {
	font-family: DM Sans,sans-serif;
	@include rem(font-size, 18px);
	line-height: 1.6em;
	font-weight: 500;
	color: $grey;
	background: #f8f9f9;
	margin: 0;
	padding: 0;
	min-height: 100%;

	&.modal-opened {
		overflow: hidden;
	}
}

/* Box sizing */

*, *:after, *:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Anchors */

a {
	color: $grey;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

/* Heading */

h1, .h1 {
	font-family: DM Sans,sans-serif;
	@include rem(font-size, 80px);
	line-height: 1.3em;
	font-weight: 400;
	color: $black;
	@include rem(margin, 0 0 30px 0);
}

h2, .h2 {
	font-family: DM Sans,sans-serif;
	@include rem(font-size, 44px);
	line-height: 1.2em;
	font-weight: 400;
	color: $black;
	@include rem(margin, 0 0 10px 0);
}

h3, .h3 {
	font-family: DM Sans,sans-serif;
	@include rem(font-size, 32px);
	line-height: 1.3em;
	font-weight: 400;
	color: $black;
	@include rem(margin, 0 0 10px 0);

	&.medium {
		@include rem(font-size, 26px);
		line-height: 1.5em;
		font-weight: 700;
	}

	&.big {
		@include rem(font-size, 44px);
		line-height: 1.2em;
		font-weight: 400;
	}
}

h4, .h4 {
	font-family: DM Sans,sans-serif;
	@include rem(font-size, 24px);
	line-height: 1.3em;
	font-weight: 500;
	color: $black;
	@include rem(margin, 0 0 10px 0);
}

h5, .h5 {
	font-family: DM Sans,sans-serif;
	@include rem(font-size, 18px);
	line-height: 1.3em;
	font-weight: 400;
	color: $black;
	@include rem(margin, 0 0 10px 0);
}

h6, .h6 {
	font-family: DM Sans,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: $black;
	@include rem(margin, 0 0 10px 0);
}

.suphead {
	@include rem(font-size, 17px);
	color: #8995a6;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

/* Paragraphs */

p {
	@include rem(margin, 0 0 20px 0);
}

address, cite {
	font-style: normal;
}

/* Lists */

ul {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;

	li {
		@include rem(padding, 0 0 0 20px);
		@include rem(margin, 0 0 5px 0);
	}

	&.unstyled li {
		padding: 0;
		background: none;
	}
}

ol {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;
	counter-reset: counter;
	margin-left: 0;

	li {
		@include rem(margin, 0 0 10px 0);
		padding: 0 0 0 20px;
		counter-increment: counter;
		position: relative;

		&:before {
			color: $red;
			content: counter(counter) ". ";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

dl {
	@include rem(margin, 0 0 20px 0);
}

dt {
	font-weight: bold;
}

/* Various */

sup, sub {
	font-size: 60%;
	line-height: 0;
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;

	&.divider {
		margin: 0;
		border-top: 2px solid rgba(9,2,44,.1);
	}
}

img {
	//width: 100%;
	max-width: 100%;
	height: auto;
}

.bold, b, strong {
	font-weight: 600;
}

.italic, i, em {
	font-style: italic;
}

.nowrap, .nobr {
	white-space: nowrap !important;
}

/************************************************************************************
CONTENT
*************************************************************************************/

.content {
	padding: 0;
}

/* Block */

.block {
	@include rem(padding, 80px 0);
	position: relative;

	&.narrow {
		@include rem(padding, 40px 0);
	}

	&.alt {
		background: $black;
	}

	&.alt-2 {
		background: $red;
	}

	&.alt-3 {
		background: #fff;
	}

	&.alt-4 {
		background: #eeeff0;
	}

	&.alpha {
		padding-top: 0;
	}

	&.omega {
		padding-bottom: 0;
	}
}

.anchor {
	position: absolute;
	left: 0;
	@include rem(top, 0);
}

.spacer {
	@include rem(height, 80px);

	&.huge {
		@include rem(height, 160px);
	}

	&.big {
		@include rem(height, 100px);
	}

	&.medium {
		@include rem(height, 60px);
	}

	&.small {
		@include rem(height, 40px);
	}
}

/* Various */

.section-head {
	@include rem(margin-bottom, 40px);
	text-align: center;

	h1, h2, h3 {
		margin-bottom: 0;
	}
}

.intro {
	max-width: 500px;
	@include rem(margin, 0 auto 40px auto);
	text-align: center;

	&.wide {
		@include rem(max-width, 750px);
	}
}

/* Pager */

.pager {
	@include rem(margin, 30px 0);
	text-align: center;
	position: relative;
	display: flex;
	justify-content: space-between;

	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	li {
		padding: 0;
		background: none;

		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include rem(width, 36px);
			@include rem(height, 36px);
			background: #666;
			text-align: center;

			&.active {
				background: #000;
				color: #fff;
				text-decoration: none;
			}
		}
	}

	.prev {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.next {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.counter {
		display: none;
	}
}

/* Gmap */

.gmap-wrap {
	position: relative;
	margin-bottom: 50px;

	.gmap {
		height: 400px;
		background: #e3e3e3;
	}

	img {
		max-width: inherit !important;
	}

	.img-desktop {
		max-width: 100% !important;
		height: auto;
	}

	.img-mobile {
		max-width: 100% !important;
		height: auto;
		display: none;
	}
}

.popup {
	font-size: 15px;
	line-height: 1.4em;
	color: #fff;
	background: $red;
	text-align: center;
	position: absolute;
	left: 50%;
	top: 50%;
	padding: 20px 30px;
	@include translate(-50%,-50%);
	margin-top: -30px;
	width: 250px;

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		bottom: -10px;
		margin-left: -20px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 20px 0 20px;
		border-color: $red transparent transparent transparent;
	}

	ul {
		margin: 0;

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
			padding: 0;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				color: #fff;
			}
		}
	}
}

/*================================= Various Content =================================*/

.last {
	margin-bottom: 0 !important;
}

/* Buttons */

.btn {
	@include rem(font-size, 17px);
	font-family: DM Sans,sans-serif;
	line-height: 1.3em;
	font-weight: 500;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: $blue;
	color: #fff;
	@include rem(padding, 0 40px);
	@include rem(height, 56px);
	@include rem(border-radius, 56px);
	text-decoration: none;
	position: relative;
	border: 2px solid $blue;
	@include transition(all, .5);

	&.small {
		@include rem(height, 48px);
	}

	&:hover {
		background-color: $red;
		border: 2px solid $red;
	}

	&.line {
		background: none;
		color: $blue;

		&:hover {
			background: $blue;
			border-color: $blue;
			color: #fff;
		}
	}

	&.alt {
		background: #fff;
		border: 2px solid #fff;

  	&:hover {
			background: $blue;
			border: 2px solid $blue;
  	}

  	&.line {
			background: none;
			color: #fff;

			&:hover {
				background: #fff;
				border-color: #fff;
				color: $black;
			}
		}
	}

	&.alt-2 {
		background: $black;
		border: 2px solid $black;

  	&:hover {
			background: $blue;
			border: 2px solid $blue;
  	}

  	&.line {
			background: none;
			color: $black;

			&:hover {
				background: $black;
				border-color: $black;
				color: #fff;
			}
		}
	}

	.icon {
		margin-right: 10px;
	}
}

.btn-load {
	@include rem(font-size, 17px);
	font-family: DM Sans,sans-serif;
	line-height: 1.3em;
	font-weight: 500;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: $red;
	color: #fff;
	@include rem(padding, 0 40px);
	@include rem(height, 48px);
	@include rem(border-radius, 8px);
	text-decoration: none;
	position: relative;
	border: 2px solid $red;
	@include transition(all, .5);
	width: 100%;

	&:hover {
		background-color: $red;
		border: 2px solid $red;
	}

	&.line {
		background: none;
		color: $red;

		&:hover {
			background: $red;
			border-color: $red;
			color: #fff;
		}
	}
}

/* SVG icons */

.icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	fill: currentColor;
}

/* Cookie Notice */

.cookie-notice {
	font-size: 14px;
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background: rgba(0,0,0,0.90);
	color: #CCC;
	text-align: center;
}

.cookie-button {
	display: inline-block;
	padding: 2px 10px;
	margin-left: 25px;
	background: #02ad65;
	color: #fff;
	text-align: center;

	&:hover {
		background: #90ca16;
		color: #fff;
	}
}

.cookie-test {
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 150px;
	padding: 25px 15px;
	background: rgba(50,50,50,0.95);
	color: #CCC;
	text-align: center
}

/* FY Apple! */

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.btn {
	-webkit-appearance: none;
}

/************************************************************************************
ENTRY
*************************************************************************************/

.entry {
	@include rem(margin, 0 0 40px 0);

	h2, h3 {
		@include rem(margin-top, 30px);

		&:first-child {
			margin-top: 0;
		}
	}

	ul {
		li {
			position: relative;
			@include rem(padding-left, 20px);
			@include rem(margin-bottom, 10px);

			&:before {
				content: '';
				width: 4px;
				height: 4px;
				position: absolute;
				left: 0;
				top: 12px;
				background: $red;
				border-radius: 50%;
			}
		}
	}
}

/************************************************************************************
TABLES
*************************************************************************************/

.entry {
	table {
		width: 100%;
		@include rem(margin, 0 0 30px 0);

		th {
			font-weight: normal;
			border-bottom: 1px solid #c9cacb;
			@include rem(padding, 10px 20px);
			background: #e3e3e3;
			color: $black;
			text-align: left;
		}

		td {
			border-bottom: 1px solid #c9cacb;
			@include rem(padding, 10px 20px);
		}
	}
}

/************************************************************************************
FORMS
*************************************************************************************/

form.std {
	label {
		@include rem(font-size,18px);
		font-weight: 700;
		line-height: 1.3em;
		color: $black;
		@include rem(margin, 0 0 10px 0);
		display: block;
	}

	input.text {
		@include rem(font-size,18px);
		font-family: DM Sans,sans-serif;
		line-height: 1.3em;
		color: $black;
		border: 1px solid rgba(9,2,44,.2);
		@include rem(padding, 0 15px);
		@include rem(height, 56px);
		border-radius: 4px;
		width: 100%;

		&.error {
			background: #fcf4f4;
			border-color: #be1e19;
		}

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	textarea {
		@include rem(font-size,18px);
		font-family: DM Sans,sans-serif;
		line-height: 1.3em;
		color: $black;
		resize: vertical;
		border: 1px solid rgba(9,2,44,.2);
		@include rem(padding, 15px 15px);
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
		max-height: 300px;

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	select {
		@include rem(font-size,17px);
		line-height: 1.3em;
		color: $black;
		width: 100%;
		@include rem(padding, 2px 10px);
	}

	option {
		@include rem(font-size,17px);
		line-height: 1.3em;
		@include rem(padding, 2px 10px);
	}

	input.text[required="required"] {
		//background-image: url('../img/req.png');
		//background-position: right center;
		//background-repeat: no-repeat;
	}

	.cell {
		@include rem(margin-bottom, 20px);
		position: relative;
		text-align: left;
	}

	.toggle {
		position: relative;
		@include rem(padding, 0 0 0 25px);

		label {
			display: inline-block;
			vertical-align: top;
			margin: 0;
		}

		input {
			margin: 0;
			position: absolute;
			left: 0;
			@include rem(top, 2px);
		}
	}

	.radio, .checker {
		position: absolute;
		left: 0;
		top: 0;
	}

	.checker {
    position: absolute;
    left: 0;
    top: 0;
    background: url('../img/checker.svg') 0 0 no-repeat;
    background-color: #fff;
    @include rem(background-position, 0 -23px);
    @include rem(background-size, 23px);
    @include transition(all, .5);
    border: 2px solid #fff;
    @include rem(width, 26px);
    @include rem(height, 26px);
    @include rem(margin, 0);
    border-radius: 50%;
    cursor: pointer;

    input {
    	opacity: 0;
    	position: absolute;
    	left: 0;
    	top: 0;
    	width: 100%;
    	height: 100%;
    	margin: 0;
    	cursor: pointer;
    }

    &.active {
      @include rem(background-position, 0 0);
      background-color: transparent;
    }

    &.has-error {
      border-color: #be1e19;
    }
  }
}

form.form-filter {
	.params {
		@include rem(margin, 0 0 20px 0);

		.cell-set, ul {
			margin: 0 -2px;
			justify-content: flex-start;
			display: flex;
			flex-wrap: wrap;
		}

		.cell, li {
			padding: 0 2px;
			@include rem(margin, 0 0 8px 0);
			flex-grow: 1;
		}
	}

	.ranges {
		margin-bottom: 25px;

		.head {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		label {
			margin-bottom: 25px;
			@include rem(margin-left, -10px);
		}

		.values {
			display: flex;
		}
	}

	.product-categories {
		li {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			@include rem(height, 40px);
			@include rem(padding, 0 5px);

			a {
				font-size: 15px;
				font-weight: 700;
				text-decoration: none;
				color: #09022C;
				white-space: nowrap;
				@include rem(height, 40px);
				@include rem(padding, 6px 0px);
				border: 1px solid rgba(193,193,193,.6);
				width: 100%;
				text-align: center;
				border-radius: 5px;
			}

			a:hover {
				border-color: $red;
			}
			a.active {
				color: $red;
				border-color: $red;
			}
		}
	}

	.toggle {
		padding: 0;

		label {
			font-size: 15px;
			font-weight: 700;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			@include rem(height, 48px);
			@include rem(padding, 0 15px);
			border: 1px solid rgba(193,193,193,.6);
			border-radius: 5px;
			color: #09022C;
			white-space: nowrap;

			&:hover {
				border-color: $red;
			}


		}

		.checker, input {
			display: none;
		}

		&.active {
			label {
				color: $red;
				border-color: $red;
			}
		}
	}

	.btn-set {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.btn {
			margin: 5px;
		}
	}

	.btn {
		margin: 0 5px;
		@include rem(height, 48px);
	}
}

form.form-order {
	max-width: 360px;

	h2 {
		@include rem(margin-bottom, 30px);
	}

	.btn {
		width: 100%;
	}
}

.range-slider-wrapper {
  @include rem(padding, 0 10px);
}

.range-slider {
  width: 100%;

  &.noUi-horizontal {
    height: 4px;
    border: none;
    box-shadow: none;
    background: #dfe0e2;

    .noUi-handle {
      @include rem(width, 24px);
      @include rem(height, 24px);
      @include rem(top, -10px);
      @include rem(right, -12px);
      //@include rem(left, 0);
      border-radius: 50%;
      background: #fff;
      border: 1px solid #C8CDD2;
      box-shadow: none;
      cursor: grab !important;

      &:before, &:after {
        content: none;
      }
    }

    .noUi-connect {
      background: $red;
    }

    .noUi-tooltip {
      @include rem(font-size, 16px);
      font-weight: 500;
      color: rgba(35, 33, 47, 0.7);
      background: none;
      border: none;
      padding: 0;
      top: -180%;
    }
  }
}

#form-ajax-content {
	position: relative;

	&.loading:after {
		content: '';
		position: absolute;
		z-index: 90;
		left: 50%;
		top: 50%;
		width: 70px;
		height: 70px;
		background: url('../img/spinner.svg') center center no-repeat;
		background-size: cover !important;
		@include translate(-50%,-50%);
	}

	& > .inner {
		opacity: 1;
		@include transition(all,.3);
	}

	&.loading > .inner {
		opacity: 0.4;
	}
}

/* Mesages & errors */

.flash-message {
	@include rem(font-size, 16px);
	padding: 0;
	background: #ebf5bc;
	border: 1px solid #96d775;
	color: #46b450;
	@include rem(padding, 15px 20px);
	@include rem(margin, 0 0 30px 0);

	&.error {
		border: 1px solid #f1c7d2;
		background: #fae7ea;
		color: #e5547a;
	}
}

/************************************************************************************
TABS
*************************************************************************************/

.tabs {
	position: relative;

	.tab-nav {
		border-bottom: 2px solid #C2C2C2;
		@include rem(margin, 0 0 40px 0);

		ul {
			@include rem(margin, 0 -12px);
			padding: 0;
			display: flex;
		}

		li {
			list-style-type: none;
			padding: 0;
			background: none;
			margin: 0 0 -2px 0;
			@include rem(padding, 0 12px);

			&:before {
				content: none;
			}

			a {
				display: block;
				text-decoration: none;
				outline: 0;
				padding: 15px 0;
				color: #C2C2C2;
				border-bottom: 2px solid #C2C2C2;

				&:hover {
					color: $red;
				}

				&.selected {
					border-color: $red;
					color: $red;
				}
			}
		}
	}

	.tab-content .tab {
		width: 100%;
		padding: 0;
		background: none;
		display: none;

		&.selected {
			position: static !important;
			display: block;
		}
	}
}

/************************************************************************************
SIMPLE LIGHTBOX
*************************************************************************************/

html.slbActive {
	overflow: hidden;
}

.slbArrow {
	font-size: 15px;

	&.prev {
		background: #fff;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		opacity: 1;
		@include transition(all, .5);

		&:before {
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(135deg);
			width: 15px;
			height: 15px;
			margin-top: -7.5px;
			margin-left: -6px;
			@include transition(all, .5);
		}

		&:hover {
			opacity: 1;
			background: #000;

			&:before {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
	}

	&.next {
		background: #fff;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		opacity: 1;
		@include transition(all, .5);

		&:before {
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(-45deg);
			width: 15px;
			height: 15px;
			margin-top: -7.5px;
			@include transition(all, .5);
		}

		&:hover {
			opacity: 1;
			background: #000;

			&:before {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
	}
}

.slbCloseBtn {
	font-size: 30px;
	line-height: 40px;
	font-weight: 400;
	font-family: peachy-keen-jf,sans-serif;
	color: #fff;
	width: 40px;
	height: 40px;
	background: #fff;
	color: $black;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	right: 0;
	top: 0;
	position: fixed;
	font-size: 0;
	@include transition(all, .5);

	&:after {
		content: '';
		width: 24px;
		height: 2px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -12px;
		margin-top: -1px;
		display: block;
		background: #000;
		@include rotate(45deg);
		@include transition(all, .5);
	}

	&:before {
		content: '';
		width: 2px;
		height: 24px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -12px;
		margin-left: -1px;
		display: block;
		background: #000;
		@include rotate(45deg);
		@include transition(all, .5);
	}

	&:hover {
		opacity: 1;
		background: #000;
		color: #fff;

		&:after {
			background: #fff;
			@include rotate(-45deg);
		}

		&:before {
			background: #fff;
			@include rotate(-45deg);
		}
	}
}

.slbImage {
	@include rem(padding, 15px 0);
}

.slbImageWrap {
	&:after {
		content: none;
	}
}

.slbCaption {
	@include rem(font-size, 16px);
	position: static;
	overflow: visible;
	white-space: normal;
	padding-top: 0;
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0,0,0,.5);
  overflow: auto;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }

  .modal-content {
  	@include rem(padding, 50px);

  	&.alt {
  		background: #f9fbfc;
  	}
  }

  .modal-box {
	  @include rem(font-size, 18px);
	  @include rem(max-width, 400px);
	  background-color: #fff;
	  line-height: 1.5em;
	  margin: auto;
	  position: relative;

	  .modal-close {
	    position: absolute;
	    right: 0;
	    top: 0;
	    text-decoration: none;
	    @include rem(width, 40px);
	    @include rem(height, 40px);
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    background: #000;
	    color: #fff;

	    &:after {
	    	content: '×';
	    	@include rem(font-size, 30px);
	    	position: absolute;
	    	left: 0;
	    	top: 0;
	    	width: 100%;
	    	height: 100%;
	    	display: flex;
	    	justify-content: center;
	    	align-items: center;
	    	color: #fff;
	    }

	    .icon {
	    	@include rem(width, 18px);
	    	@include rem(height, 18px);
	    	transform: rotate(0deg);
	    	@include transition(all, .3);
	    }

	    &:hover {
	    	background: #fff;

	    	&:after {
	    		color: $black;
	    	}

	    	.icon {
	    		transform: rotate(90deg);
	    	}
	    }
	  }

	  &.modal-order {
			width: 100%;
			@include rem(max-width, 580px);
			background: #e5e5e5;
		}
	}

	.modal-box-wrap {
	  width: 100%;
	  //max-width: 500px;
	  @include rem(padding, 50px 15px 15px);
	  margin: auto;
	  overflow: hidden;
	  position: relative;
	  animation-name: animateTop;
	  animation-duration: .4s;
	}
}

@keyframes animateTop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/************************************************************************************
GLIDE
*************************************************************************************/

.glide__slides {
	margin: 0;
}

.glide__slide {
	padding: 0 !important;
	margin: 0 !important;

	&:before {
		content: none !important;
	}
}

.glide-nav {
	@include rem(margin-top, 20px);
	position: relative;

	.glide__arrow--left {
		position: absolute;
		left: 0;
		top: 0;
		width: 43px;
		height: 37px;
		cursor: pointer;
	}

	.glide__arrow--right {
		position: absolute;
		right: 0;
		top: 0;
		width: 43px;
		height: 37px;
		cursor: pointer;
	}
}

.glide__bullets {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 40px;

	.glide__bullet {
		width: 40px;
		border-radius: 2px;
		padding: 10px 0;
		margin: 0 7px 0 0;
		border: none;
		cursor: pointer;
		@include transition(all, .5);

		span {
			display: block;
			width: 40px;
			height: 4px;
			background: #868686;
		}

		&.glide__bullet--active, &:hover {
			span {
				background: $red;
			}
		}
	}
}

/* Image slider */

.img-slider {
	.item {
		//padding: 0 20px;
	}

	.glide__slides {
		//margin: 0 -20px;
	}
}

/* Teaser slider */

.teaser-slider {
	.item {
		display: flex;
	}
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}