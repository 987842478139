/************************************************************************************
FOOTER
*************************************************************************************/

.footer {
  background: #fff;
  color: $black;

  .f_1 {
    @include rem(padding, 55px 0);
  }

  .f_2 {
    @include rem(padding, 15px 0);
    border-top: 2px solid rgba(9,2,44,.2);

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .contact-list {
    text-align: right;
  }

  .foot-item {
    @include rem(padding, 20px 0);
  }

  .foot-nav {
    a {
      color: $black;
    }
  }

  ul {
    li {
      padding: 0;
    }
  }

  a {
    color: #000;
    //text-decoration: none;

    &:hover {
      //text-decoration: underline;
    }
  }
}

.copy {
  @include rem(font-size, 16px);
  line-height: 1.3em;
  margin: 5px 0; 
}

.mw {
  position: relative;
  color: #c6c6c6;
  display: block;

  &:hover {
    color: #00daee;
  }

  .text {
    position: absolute;
    left: 0;
    top: 0;
    text-indent: -80000px;
    display: block;
  }

  .icon-mw {
    width: 70px;
    height: 30px;
  }
}

.orwin {
  @include rem(font-size, 16px);
  color: #e3e3e3;
  margin: 5px 0;

  a {
    text-decoration: none;
    color: rgba(0,0,0,.3);
    @include transition(all, .3);

    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }

  .text {
    position: absolute;
    left: 0;
    top: 0;
    text-indent: -80000px;
    display: block;
  }
}

