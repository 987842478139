/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1199px) {
  h1, .h1 {
    @include rem(font-size, 60px);
  }

  h2, .h2 {
    @include rem(font-size, 38px);
  }

  .spacer {
    @include rem(height, 80px);
  }
}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 991px) {
  html {
    font-size: 15px;
  }

  .main-nav {
    display: none;
  }

  .nav-btn {
    display: block;
  }

  .mobile-nav-wrap {
    display: block;
  }

  h1, .h1 {
    @include rem(font-size, 50px);
    line-height: 1.3em;
  }

  h2, .h2 {
    @include rem(font-size, 35px);
    line-height: 1.3em;
  }

  .spacer {
    @include rem(height, 60px);
  }

  .block {
    @include rem(padding, 60px 0);
  }

  .text-img {
    .row {
      margin: 0 -15px;
    }

    .img {
      padding: 0 15px;
    }

    .text {
      padding: 0 15px;
    }
  }

  .tab-nav {
    overflow-x: auto;

    ul {
      li {
        min-width: 80px;
      }
    }
  }

  .table-wrap {
    overflow-x: auto;
  }

  .teaser-slider {
    .item {
      display: block;
    }
  }

  .teaser-hp {
    display: block;
    min-height: 0;

    .teaser-content {
      margin-left: 0;
      background: #c7c6c6;
      min-height: 0;

      .inner {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .manimat-head {
    h3, .h3 {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .dropdown {
    display: none !important;
  }

  form.form-filter {
    .params {
      .cell-set, ul {
        
      }

      .cell, li {
        flex-grow: 0;
      }
    }

    .product-categories {
      li {
        a {
          width: auto;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }

  h1, .h1 {
    @include rem(font-size, 50px);
    line-height: 1.2em;
  }

  h2, .h2 {
    @include rem(font-size, 35px);
    line-height: 1.3em;
  }

  h3, .h3 {
    @include rem(font-size, 28px);
    line-height: 1.3em;

    &.medium {
      @include rem(font-size, 28px);
      line-height: 1.3em;
    }

    &.big {
      @include rem(font-size, 28px);
      line-height: 1.3em;
    }
  }

  .spacer {
    @include rem(height, 40px);
  }

  .text-img {
    .img {
      @include rem(margin-bottom, 20px);
      order: 0 !important;
    }

    .text {
      order: 1 !important;
    }
  }

  .services {
    .item {
      left: 0;
    }
  }

  .product-text {
    .inner {
      max-width: 100%;
    }
  }

  .gmap-wrap {
    .img-desktop {
      display: none;
    }

    .img-mobile {
      display: block;
    }
  }
}

/*========================= Viewport width 575px and lower =========================*/

@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }

  h1, .h1 {
    @include rem(font-size, 40px);
    line-height: 1.3em;
  }

  h2, .h2 {
    @include rem(font-size, 30px);
    line-height: 1.3em;
  }

  h3, .h3 {
    @include rem(font-size, 25px);
    line-height: 1.3em;
  }

  .teaser-hp {
    .teaser-content {
      .inner {
        max-width: 100%;
      }
    }
  }

  /*.spacer {
    @include rem(height, 30px);
  }

  .block {
    @include rem(padding, 40px 0);
  }*/
}