/**
 * Colors
 */
$black: #1C1B25;
$red: #DA1105;
$blue: #073F93;
$grey: rgba(28,26,40,.6);
$greyLight: #eeeff0;
$white: #fff;

/**
/* Font weight variables
 */
$font-light: 100;
$font-normal: 300;
$font-medium: 500;
$font-semibold: 700;
$font-bold: 900;