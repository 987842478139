/************************************************************************************
LAYOUT
*************************************************************************************/

.box-shadow {
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1), 0 16px 24px -16px rgba(0,0,0,0.1);
}

.contact-bar {
	color: #fff;
	@include rem(padding, 30px 0);

	a {
		color: #fff;
		//text-decoration: none;

		&:hover {
			//text-decoration: underline;
		}
	}

	h3 {
		@include rem(margin, 5px 20px 5px 0);
		color: #fff;
	}

	ul {
		@include rem(margin, 5px 0);

		li {
			margin: 0;
			padding: 0;
		}
	}

	.inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.text-img {
	.row {
		margin: 0 -60px;
	}

	.img {
		@include rem(margin-bottom, 40px);
		padding: 0 60px;

		&.img-symbol {
			.inner {
				position: relative;

				img {
					position: relative;
					z-index: 10;
				}

				&:after {
					content: '';
					width: 160%;
					height: 160%;
					background: url('../img/symbol.svg') 0 0 no-repeat;
					background-size: contain !important;
					position: absolute;
					left: -95%;
					top: -20%;
					z-index: 1;
					pointer-events: none;
				}
			}
		}
	}

	.text {
		padding: 0 60px;
		position: relative;
		z-index: 10;
	}

	&.alt {
		.img {
			order: 1;
		}

		.text {
			order: 0;
		}
	}
}

.more-link {
	color: $red;
	display: flex;
	align-items: center;
	text-decoration: none;
	@include transition(all, .3);
	text-decoration: underline;

	&:before {
		content: '';
		@include rem(width, 48px);
		@include rem(height, 48px);
		@include rem(flex, 0 0 48px);
		@include rem(margin-right, 16px);
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: $red url('../img/arr-r.svg') center center no-repeat;
		border: 2px solid $red;
		@include transition(all, .3);
	}

	&:hover {
		color: #fff;
		text-decoration: none;

		&:before {
			content: '';
			@include rem(width, 48px);
			@include rem(height, 48px);
			@include rem(margin-right, 16px);
			border-radius: 50%;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;
			border-color: #fff;
		}
	}

	&.alt {
		&:before {
			background: transparent url('../img/arr-r-red.svg') center center no-repeat;
		}
	}

	&.small {
		@include rem(width, 40px);
		@include rem(height, 40px);
		@include rem(flex, 0 0 40px);
	}
}

.photogallery {
	.item {
		@include rem(margin, 0 0 20px 0);
	}
}

.box {
	background: #e5e5e5;
	color: $black;
	@include rem(padding, 40px);
	border-radius: 5px;
}

.price-box {
	background: #fff;
	color: $black;
	@include rem(padding, 24px);
	@include rem(margin, 0 0 30px 0);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 5px 20px 0 rgba(0,0,0,0.05), 0 16px 24px -16px rgba(0,0,0,0.1);

	.price {
		@include rem(font-size, 32px);
		line-height: 1.3em;
		display: block;
	}

	.subhead {
		font-weight: 600;
		color: $grey;
		display: block;
	}

	.img {
		@include rem(width, 64px);
		@include rem(height, 64px);
		background: rgba(35,33,47,0.05);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;

		.icon {
			@include rem(width, 27px);
			@include rem(height, 27px);
		}
	}
}

.product-list {
	.item {
		@include rem(margin, 0 0 48px 0);
		display: flex;

		.img {
			border-radius: 5px 5px 0 0;
			display: block;
			overflow: hidden;
		}

		.text {
			background: #DEE0E2;
			@include rem(padding, 32px 24px);
			border-radius: 0 0 5px 5px;
			overflow: hidden;
			flex-grow: 1;

			h3 {
				color: #09022C;

				a {
					color: #09022C;
					text-decoration: none;

					&:hover {
						color: $red;
					}				
				}
			}
		}

		.head {
			@include rem(margin, 0 0 10px 0);
		}

		.params {
			font-size: 14px;
			@include rem(margin, 0 0 10px 0);

			ul {
				margin-bottom: 0;

				li {
					line-height: 1.5em;
					display: flex;
					justify-content: space-between;
					padding: 0;
					margin: 0 0 5px 0;

					&:before {
						content: none;
					}
				}
			}
		}

		.price {
			.val {
				@include rem(font-size, 26px);
				line-height: 1.3em;
				font-weight: 700;
				color: $red;
				display: block;
			}

			.vat {
				font-size: 12px;
				line-height: 1.3em;
				font-weight: 500;
				display: block;
			}
		}
	}

	.item-inner {
		@include transition(all, .3);
		position: relative;
		top: 0;
		border-radius: 5px;
		display: flex;
		flex-direction: column;

		&:hover {
			top: -4px;
			box-shadow: 0 3px 20px 0 rgba(0,0,0,0.2), 0 16px 24px -16px rgba(0,0,0,0.1);
		}
	}
}

.product-filter {
	background: #fff;
	@include rem(padding, 30px);
	@include rem(margin-bottom, 50px);
	border-radius: 4px 4px 0 0;
	min-height: 190px;
}

.product-detail {
	.params {
		@include rem(font-size, 18px);
		@include rem(padding-top, 30px);
		@include rem(margin, 0 0 50px 0);

		ul {
			margin-bottom: 0;

			li {
				line-height: 1.5em;
				display: flex;
				//justify-content: space-between;
				padding: 0;
				margin: 0 0 15px 0;

				&:before {
					content: none;
				}

				.label {
					flex: 0 0 160px;
				}

				.val {
					color: $black;
				}
			}
		}
	}

	.product-price {
		@include rem(margin, 0 0 30px 0);

		.price {
			.val {
				@include rem(font-size, 44px);
				line-height: 1.3em;
				color: $red;
			}

			.vat {
				@include rem(font-size, 18px);
				line-height: 1.3em;
				color: $grey;
			}
		}
	}

	.product-img {
		.img-main {
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			overflow: hidden;
			padding: 15px;
			min-height: 412px;
			@include rem(margin, 0 0 25px 0);
		}

		.img-gallery {
			.item {
				@include rem(margin, 0 0 25px 0);
			}
		}
	}

	.product-text {

	}

	.btn-set {
		.btn {
			width: 100%;
			max-width: 360px;
		}
	}
}

.product-text {
	display: flex;
	justify-content: flex-end;

	.inner {
		width: 100%;
		max-width: 458px;
	}
	
	h1 {
		@include rem(padding-bottom, 40px);
		@include rem(min-height, 130px);
	}
}

.product-order {
	.product-img {
		padding-bottom: 100%;
		border-radius: 50%;
		position: relative;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8e8e8+0,c4c4c4+100&0.21+0,0.19+100 */
		background: -moz-linear-gradient(top,  rgba(232,232,232,0.21) 0%, rgba(196,196,196,0.19) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(232,232,232,0.21) 0%,rgba(196,196,196,0.19) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(232,232,232,0.21) 0%,rgba(196,196,196,0.19) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36e8e8e8', endColorstr='#30c4c4c4',GradientType=0 ); /* IE6-9 */

		.img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.categories {
	.item {
		@include rem(margin, 0 0 20px 0);

		.img {
			flex: 0 0 100%;
		}

		.text {
			flex: 0 0 100%;
			margin-left: -100%;
			@include rem(padding, 24px);
			position: relative;
			z-index: 20;

			h3 {
				color: #fff;
				font-w-eight: 400;
				text-decoration: underline;
			}
		}

		.head {
			@include rem(margin, 0 0 10px 0);
			display: flex;
			justify-content: space-between;

			&:after {
				content: '';
				@include rem(flex, 0 0 40px);
				@include rem(width, 40px);
				@include rem(height, 40px);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				border: 1px solid $red;
				background: url('../img/arr-r-red.svg') center center no-repeat;
				margin-left: 20px;
				margin-top: 10px;
				@include transition(all, .3);
			}
		}
	}

	.item-inner {
		display: flex;
		border-radius: 8px;
		overflow: hidden;
		text-decoration: none;
		position: relative;
		box-shadow: 0 5px 20px 0 rgba(0,0,0,0.05), 0 16px 24px -16px rgba(0,0,0,0.1);

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: block;
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.9+0,0.5+100 */
			background: -moz-linear-gradient(top,  rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
			@include transition(all, .3);
			opacity: 1;
		}

		&:hover {
			h3 {
				text-decoration: none;
			}

			&:after {
				opacity: .8;
			}

			.head {
				&:after {
					border-color: #fff;
					background-color: #fff;
				}
			}
		}
	}
}

.logos {
	.item {
		margin-bottom: 20px;
	}

	.img {
		background: #fff;
		border-radius: 5px;
		min-height: 110px;
		display: flex;
		align-items: center;
		padding: 15px;
	}
}

.banner {
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c30000+0,fd4016+100 */
	background: rgb(195,0,0); /* Old browsers */
	background: -moz-linear-gradient(left,  rgba(195,0,0,1) 0%, rgba(253,64,22,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  rgba(195,0,0,1) 0%,rgba(253,64,22,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  rgba(195,0,0,1) 0%,rgba(253,64,22,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c30000', endColorstr='#fd4016',GradientType=1 ); /* IE6-9 */
	@include rem(padding, 40px);
	color: #fff;
	border-radius: 10px;

	h3 {
		color: #fff;

		.alt {
			color: #000;
		}		
	}

	&.center {
		text-align: center;
	}

	&.alt {
		color: $black;
		background: none;

		h3 {
			color: $black;

			.alt {
				color: $red;
			}		
		}
	}
}

.text-red {
	color: $red;
}

.manimat-head {
	h3, .h3 {
		width: 120%;
	}

	.frame {
		max-width: 550px;
	}
}

.spacer {
	@include rem(height, 96px);
}

.post-sections {
	h1, h2 {
		margin-bottom: 0;
	}

	table {
		font-size: 16px;
		width: 100%;
		background: #fff;
		color: $black;
		@include rem(margin-bottom, 40px);

		th {
			text-align: left;
			@include rem(padding, 14px 16px);
		}

		td {
			text-align: left;
			@include rem(padding, 14px 16px);
		}

		tr {
			&:nth-child(odd) {
				td {
					background: rgba(35,33,47,.05);
				}
			}
		}
	}

	ol {
		li {
			@include rem(margin-bottom, 10px);
		}
	}

	ul {
		li {
			position: relative;
			@include rem(padding-left, 20px);
			@include rem(margin-bottom, 10px);

			&:before {
				content: '';
				width: 4px;
				height: 4px;
				position: absolute;
				left: 0;
				top: 12px;
				background: $red;
				border-radius: 50%;
			}
		}
	}
}

.invert {
	color: #fff;

	h1, h2, h3, h4 {
		color: #fff;
	}
}

.services {
	.item {
		@include rem(margin-bottom, 10px);
		position: relative;
		@include rem(left, -24px);

		.img {
			@include rem(width, 96px);
			@include rem(height, 96px);
			@include rem(flex, 0 0 96px);
			@include rem(margin-right, 40px);
			display: flex;
			align-items: center;
			justify-content: center;
			background: $red;
			background: radial-gradient(67.19% 67.19% at 0% 0%, #FF3F3F 0%, #DA1105 100%);
			box-shadow: 0px 12px 16px -6px #DA1104;
			border-radius: 4px;
			color: #fff;

			.icon {
				@include rem(width, 48px);
				@include rem(height, 48px);
				@include rem(flex, 0 0 48px);
			}
		}

		.text {
			h3 {
				text-decoration: underline;
			}

			p {
				margin-bottom: 0;
			}
		}
	}

	.item-inner {
		text-decoration: none;
		display: flex;
		//background: #fff;
		@include rem(padding, 24px);		
		border-radius: 4px;
		@include transition(all, .3);

		&:hover {
			background: rgba(255, 255, 255, 0.8);
			box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);

			h3 {
				text-decoration: none;
			}
		}
	}
}

.contact-data {
	color: $black;
	font-weight: 400;

	.item {
		margin-bottom: 20px;
	}

	ul {
		li {
			padding: 0;
		}
	}

	a {
		color: $black;
		//text-decoration: none;

		&:hover {
			//text-decoration: underline;
			color: $red;
		}
	}
}

.zero {
	width: 100%;
	height: 708px;
	//box-shadow: inset 0px 6px 40px rgba(0, 0, 0, 0.12);
	border-radius: 1000px;
	//box-shadow: 0px 6px 40px 0px rgba(0, 0, 0, 0.12) inset;
	background: url('../img/zero.svg') center center no-repeat;
	background-size: contain !important;
	display: flex;
	align-items: center;
	justify-content: center;

	.frame {
		//background: #eeeff0;
		position: relative;
		left: -10%;
	}
}

.mt--80 {
	@include rem(margin-top, -80px);
	position: relative;
	z-index: 30;
}

.mt--130 {
	@include rem(margin-top, -130px);
	position: relative;
	z-index: 30;
}

form .contact-addon {
	display: none;
}